import React from 'react';

import Header from './Header/header';
import InfoBar from './InfoBar/infoBar';
import ProgressBar from './ProgressBar/progress';

const sliderC = ({ startWeatherData, startWeatherDataDaily, startWeatherForecast }) => (
  <>
    <Header />
    <InfoBar
      startWeatherData={startWeatherData}
      startWeatherDataDaily={startWeatherDataDaily}
      startWeatherForecast={startWeatherForecast}
    />
    <ProgressBar />
  </>
);

export default sliderC;
