import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/pl';

import styles from './header.module.scss';

const Header = () => (
  <div className={styles.wrapper}>
    <div className={styles.innerWrapper}>
      <img
        className={styles.icon}
        src={`${process.env.PUBLIC_URL}/assets/logoZakopane.svg`}
        alt="Logo Zakopane"
      />
      <div className={styles.time}>
        <p className={styles.time__date}>
          <Moment interval={1000} format="dddd, DD.MM.YYYY" />
        </p>
        <div className={styles.time__line} />
        <p className={styles.time__time}>
          <Moment interval={1000} format="HH:mm" />
        </p>
      </div>
    </div>
  </div>
);

export default Header;
