import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/pl';
import { getIcon, getWind } from '../../../helpers/weather';

import styles from './infoBar.module.scss';

const InfoBar = ({ startWeatherData, startWeatherDataDaily }) => {
  const date = new Date();

  return (
    <div className={styles.wrapper}>
      <div className={styles.time}>
        <img
          className={styles.time__icon}
          src={`${process.env.PUBLIC_URL}/assets/logoZakopane.svg`}
          alt="Logo Zakopane"
        />
        <p className={styles.time__time}>
          <Moment interval={1000} format="HH:mm" />
        </p>
        <p className={styles.time__date}>
          <Moment interval={1000} format="dddd, DD.MM.YYYY" />
        </p>
      </div>
      <div className={styles.weather}>
        <img
          className={styles.weather__icon}
          src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/${
            date.getHours() < Number(startWeatherDataDaily?.sunset?.split(':')[0]) &&
            date.getHours() > Number(startWeatherDataDaily?.sunrise?.split(':')[0])
              ? 'day'
              : 'night'
          }/${getIcon(startWeatherData.weatherCode)}`}
          alt="Ikona pogody"
        />
        <div>
          <p className={styles.weather__degree}>{startWeatherData.temperatureValue}&deg;C</p>
          <p className={styles.info__paragraph}>
            Odczuwalna:{' '}
            <span style={{ fontWeight: 'bold' }}>
              {startWeatherData.perceivedTemperature}&deg;C
            </span>
          </p>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.info__innerWrapper}>
          <img
            className={styles.info__iconWind}
            src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/day/wind.svg`}
            alt="Ikona siły wiatru"
          />
          <p className={styles.info__paragraph}>
            Wiatr:&nbsp;
            <span style={{ fontWeight: 'bold' }}>
              {getWind(startWeatherData.windDirection)}&nbsp;
              {startWeatherData.windValue}km/h&nbsp;
            </span>
          </p>
        </div>
        <div className={styles.info__innerWrapper}>
          <img
            className={styles.info__iconHumidity}
            src={`${process.env.PUBLIC_URL}/assets/img/weather/icon__humidity.svg`}
            alt="Ikona lawiny"
          />
          <p className={styles.info__paragraph}>
            Wilgotność:&nbsp;
            <span style={{ fontWeight: 'bold' }}>{startWeatherData.humidityValue}%</span>
          </p>
        </div>
        <div className={styles.info__innerWrapper}>
          <img
            className={styles.info__iconPressure}
            src={`${process.env.PUBLIC_URL}/assets/img/weather/icon__pressure.svg`}
            alt="Ikona ciśnienia"
          />
          <p className={styles.info__paragraph}>
            Ciśnienie:{' '}
            <span style={{ fontWeight: 'bold' }}>{startWeatherData.atmosphericPressure} hPa</span>
          </p>
        </div>
      </div>
      <div className={styles.berg}>
        <img
          className={styles.berg__logo}
          src={`${process.env.PUBLIC_URL}/assets/logoBR.png`}
          alt="Logo Berg Regions"
        />
        <p className={styles.berg__content}>
          Powered by <span>BergRegions.pl</span>
        </p>
      </div>
    </div>
  );
};

export default InfoBar;
