import React from 'react';

import ProgressBar from './ProgressBar/progress';
import InfoBar from './InfoBar/infoBar';
import Slider from './Slider/slider';

const SliderMain = ({ startWeatherData, startWeatherDataDaily }) => (
  <>
    <InfoBar startWeatherData={startWeatherData} startWeatherDataDaily={startWeatherDataDaily} />
    <Slider />
    <ProgressBar />
  </>
);

export default SliderMain;
