import React from 'react';

import styles from './movie.module.scss';

const Movie = () => (
  <div className={styles.wrapper}>
    <video className={styles.movie} autoPlay muted playsInline crossOrigin="anonymous">
      <source src={`${process.env.PUBLIC_URL}/assets/Zakopane.mp4`} type="video/mp4" />
    </video>
  </div>
);

export default Movie;
