import React from 'react';

import styles from './slider.module.scss';

const Slider = () => (
  <div>
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/Stacja_Zakopane.jpg`}
      alt="Plakat"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/parking.png`}
      alt="Plakat"
      className={styles.wrapper}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/informator.png`}
      alt="Plakat"
      className={styles.wrapperLeft}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/gory_morze.jpg`}
      alt="Plakat"
      className={styles.wrapperRight}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/fudali.png`}
      alt="Plakat"
      className={styles.wrapperLeft}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/puchar.png`}
      alt="Plakat"
      className={styles.wrapperRight}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/nz.png`}
      alt="Plakat"
      className={styles.wrapperLeft}
    />
    <img
      src={`${process.env.PUBLIC_URL}/assets/img/cooltoralny.jpg`}
      alt="Plakat"
      className={styles.wrapperRight}
    />
  </div>
);

export default Slider;
