import React, { useState, useEffect } from 'react';
import axios from 'axios';

import SliderMain from 'components/sliderMain';
import SliderWeather from 'components/sliderWeather';
import SliderMovie from 'components/sliderMovie';
import GlobalStyle from '../theme/GlobalStyle';
import styles from './App.module.scss';

import withClearCache from '../ClearCache';

const App = () => <ClearCacheComponent />;

const MainApp = () => {
  const [startWeatherData, setStartWeatherData] = useState([]);
  const [startWeatherDataDaily, setStartWeatherDataDaily] = useState([]);
  const [startWeatherForecast, setStartWeatherForecast] = useState([]);
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const sliderTime = 51;
  const weatherTime = 20 + sliderTime;
  const sliderMovieFirst = 21 + weatherTime;
  const repeat = sliderMovieFirst;

  const Play = () => {
    setFirst(true);

    setTimeout(() => {
      setSecond(true);
      setFirst(false);
    }, sliderTime * 1000);

    setTimeout(() => {
      setThird(true);
      setSecond(false);
    }, weatherTime * 1000);

    setTimeout(() => {
      setThird(false);
    }, sliderMovieFirst * 1000);
  };

  useEffect(() => {
    async function fetchData() {
      const lat = 49.29378;
      const lon = 19.95375;
      axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Access-Control-Allow-Headers':
            'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
        },

        url: `https://api.bergregions.pl/api/v1/weather?lat=${lat}&lon=${lon}`,
      })
        .then((response) => {
          setStartWeatherData(response.data.currentCondition);
          setStartWeatherDataDaily(response.data.daily[0]);
          setStartWeatherForecast(response.data.today);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
    fetchData();

    Play();
    setInterval(() => {
      Play();
    }, repeat * 1000);

    const check = () => {
      const minutes = new Date().getMinutes();
      if (minutes === 0 || minutes === 30) {
        fetchData();
      }
    };

    setInterval(() => {
      check();
    }, 1000 * 60);
  }, []);

  return (
    <>
      <GlobalStyle />
      <div className={styles.wrapper}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/background.jpg`}
          alt="Background"
          className={styles.background}
        />
        {first && (
          <SliderMain
            startWeatherData={startWeatherData}
            startWeatherDataDaily={startWeatherDataDaily}
          />
        )}
        {second && (
          <SliderWeather
            startWeatherData={startWeatherData}
            startWeatherDataDaily={startWeatherDataDaily}
            startWeatherForecast={startWeatherForecast}
          />
        )}
        {third && <SliderMovie />}
      </div>
    </>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

export default App;
