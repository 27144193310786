import React from 'react';

import Progress from './ProgressBar/progress';
import Movie from './Movie/movie';

const SliderThird = () => (
  <>
    <Movie />
    <Progress />
  </>
);

export default SliderThird;
