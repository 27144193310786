import React from 'react';
import { getIcon, getWind } from '../../../helpers/weather';

import styles from './infoBar.module.scss';
import Chart from './chart';

const InfoBar = ({ startWeatherData, startWeatherDataDaily, startWeatherForecast }) => {
  const date = new Date();

  return (
    <div className={styles.wrapper}>
      <div className={styles.location}>
        <p className={styles.location__name}>
          <span style={{ fontWeight: 'bold' }}>Aktualna pogoda: Zakopane</span> (750m n.p.m.)
        </p>
      </div>
      <div className={styles.weather}>
        <div className={styles.details}>
          <img
            className={styles.details__icon}
            src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/${
              date.getHours() < Number(startWeatherDataDaily?.sunset?.split(':')[0]) &&
              date.getHours() > Number(startWeatherDataDaily?.sunrise?.split(':')[0])
                ? 'day'
                : 'night'
            }/${getIcon(startWeatherData.weatherCode)}`}
            alt="Ikona pogody"
          />
          <div>
            <p className={styles.details__degree}>{startWeatherData.temperatureValue}&deg;C</p>
            <p className={styles.info__paragraph}>
              Odczuwalna:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {startWeatherData.perceivedTemperature}&deg;C
              </span>
            </p>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.info__innerWrapper}>
            <img
              className={styles.info__iconSun}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/day/sunny.svg`}
              alt="Ikona słońca"
            />
            <p className={styles.info__paragraph}>
              Czas ze słońcem:&nbsp;
              <span style={{ fontWeight: 'bold' }}>
                {startWeatherDataDaily.hoursOfSun}&nbsp;godz.
              </span>
            </p>
          </div>
          <div className={styles.info__innerWrapper}>
            <img
              className={styles.info__iconWind}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/day/wind.svg`}
              alt="Ikona siły wiatru"
            />
            <p className={styles.info__paragraph}>
              Wiatr:&nbsp;
              <span style={{ fontWeight: 'bold' }}>
                {getWind(startWeatherData.windDirection)}&nbsp;
                {startWeatherData.windValue}km/h&nbsp;
              </span>
            </p>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.info__innerWrapper}>
            <img
              className={styles.info__iconHumidity}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/icon__humidity.svg`}
              alt="Ikona wilgotności"
            />
            <p className={styles.info__paragraph}>
              Wilgotność:&nbsp;
              <span style={{ fontWeight: 'bold' }}>{startWeatherData.humidityValue}%</span>
            </p>
          </div>
          <div className={styles.info__innerWrapper}>
            <img
              className={styles.info__iconPressure}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/day/fog.svg`}
              alt="Ikona ciśnienia"
            />
            <p className={styles.info__paragraph}>
              Ciśnienie:{' '}
              <span style={{ fontWeight: 'bold' }}>{startWeatherData.atmosphericPressure} hPa</span>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.forecast}>
        <p className={styles.forecast__name}>
          <span style={{ fontWeight: 'bold' }}>Prognoza pogody</span>
        </p>
        <div>
          {startWeatherForecast.slice(0, 5).map((item) => (
            <img
              className={styles.forecast__icon}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/${
                item.hour < Number(startWeatherDataDaily?.sunset?.split(':')[0]) &&
                item.hour > Number(startWeatherDataDaily?.sunrise?.split(':')[0])
                  ? 'day'
                  : 'night'
              }/${getIcon(item.weatherCode)}`}
              alt="Ikona pogody"
              key={item.hour}
            />
          ))}
        </div>
        <div className={styles.chartBox}>
          <Chart startWeatherForecast={startWeatherForecast} />
          <div className={styles.legendBox}>
            <div className={styles.legendBox__box}>
              <div className={styles.legendBox__line} />
              <p className={styles.legendBox__paragraph}>Temperatura prognozowana</p>
            </div>
            <div className={styles.legendBox__box}>
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <div className={styles.legendBox__lineDot} />
              <p className={styles.legendBox__paragraph}>Temperatura odczuwalna</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.berg}>
        <img
          className={styles.berg__logo}
          src={`${process.env.PUBLIC_URL}/assets/logoBR.png`}
          alt="Logo Berg Regions"
        />
        <p className={styles.berg__content}>
          Powered by <span>BergRegions.pl</span>
        </p>
      </div>
    </div>
  );
};

export default InfoBar;
